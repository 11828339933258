// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-docs-accounts-membership-js": () => import("./../../src/pages/docs/accounts/membership.js" /* webpackChunkName: "component---src-pages-docs-accounts-membership-js" */),
  "component---src-pages-docs-getting-started-workflow-js": () => import("./../../src/pages/docs/getting-started/workflow.js" /* webpackChunkName: "component---src-pages-docs-getting-started-workflow-js" */),
  "component---src-pages-docs-index-js": () => import("./../../src/pages/docs/index.js" /* webpackChunkName: "component---src-pages-docs-index-js" */),
  "component---src-pages-docs-integrations-bitbucket-server-js": () => import("./../../src/pages/docs/integrations/bitbucket-server.js" /* webpackChunkName: "component---src-pages-docs-integrations-bitbucket-server-js" */),
  "component---src-pages-docs-integrations-github-js": () => import("./../../src/pages/docs/integrations/github.js" /* webpackChunkName: "component---src-pages-docs-integrations-github-js" */),
  "component---src-pages-docs-integrations-index-js": () => import("./../../src/pages/docs/integrations/index.js" /* webpackChunkName: "component---src-pages-docs-integrations-index-js" */),
  "component---src-pages-docs-ip-ranges-js": () => import("./../../src/pages/docs/ip-ranges.js" /* webpackChunkName: "component---src-pages-docs-ip-ranges-js" */),
  "component---src-pages-goodbye-js": () => import("./../../src/pages/goodbye.js" /* webpackChunkName: "component---src-pages-goodbye-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-cookie-policy-js": () => import("./../../src/pages/legal/cookie-policy.js" /* webpackChunkName: "component---src-pages-legal-cookie-policy-js" */),
  "component---src-pages-legal-index-js": () => import("./../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-terms-of-service-js": () => import("./../../src/pages/legal/terms-of-service.js" /* webpackChunkName: "component---src-pages-legal-terms-of-service-js" */)
}

